import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../../styles/variables";
import MailButton from "./MailButton";
import { getImgix } from "../helpers/getImgix";

const ItemElem = styled.article`
  text-align: center;

  h4 {
    font: 20px / 30px "FS Medium";
    margin-bottom: 20px;
  }

  h5 {
    font: 18px / 22px "FS Medium";
    margin-bottom: 10px;
  }

  a,
  p {
    display: block;
    font: 18px / 22px "FS Regular";
    text-decoration: none;
    margin-bottom: 18px;
  }

  @media screen AND (min-width: 1024px) {
    > div.onlyMobile {
      display: none;
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: calc((100% - 120px) / 3);
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      font: 28px / 36px "FS Medium";
      margin-bottom: 22px;
    }

    h5 {
      font: 24px / 36px "FS Medium";
      margin-bottom: 12px;
    }

    a,
    p {
      font: 24px / 36px "FS Regular";
      margin-bottom: 22px;
    }
  }
`;

const MitarbeiterImage = styled.div`
  margin-bottom: 16px;
  background: url(${(props) => props.src});
  background-position: center;
  background-size: calc(99%);
  margin: 0 auto;
  height: auto;
  max-width: 300px;
  max-height: 300px;
  line-height: 0;

  > img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin: -1px;
    padding: 0;
    line-height: 0;
  }

  @media screen AND (min-width: 1024px) {
    width: 100%;
  }
`;

const MitarbeiterItem = ({ color, content }) => (
  <ItemElem>
    <div>{content.job ? <h4>{content.job}</h4> : ""}
    {content.darstellungsortTelefonnummer && content.telefon && (
        <a href={"tel:" + content.telefon} title="Telefon Link">
          {content.telefon}
        </a>)}</div>
    <div>
      {content.bild.length > 0 ? (
        <MitarbeiterImage
          src={getImgix(content.bild[0].url, imgix.mitarbeiterImage).newSrc}
        >
          <img src="/assets/gestaltungselemente/spirale.png" />
        </MitarbeiterImage>
      ) : (
        ""
      )}
    </div>
    <div className="onlyMobile">
      {content.mitarbeitername ? <h5>{content.mitarbeitername}</h5> : ""}
      {content.text ? (
        <div dangerouslySetInnerHTML={{ __html: content.text }}></div>
      ) : (
        ""
      )}

      {content.darstellungsortTelefonnummer === false && content.telefon && (
        <a href={"tel:" + content.telefon} title="Telefon Link">
          {content.telefon}
        </a>)}

      {content.email ? <MailButton color={color} email={content.email} /> : ""}
    </div>
  </ItemElem>
);

export default MitarbeiterItem;
