import React from "react";
import Button from "./Button";
import Quote from "./Text/Quote";
import Fliesstext from "./Text/Fliesstext";
import CenteredImage from "./CenteredImage";
import Wrapper from "./Wrappers/Wrapper";
import { getColor } from "./helpers/getColor";
import CenteredTextBGColor from "./CenteredTextBGColor";

interface AbschlussElementeProps {
  content: [
    {
      zitat: string;
      autor: string;
      buttonText: string;
      buttonLinkUrl: string;
      buttonLinkDatei: [
        {
          url: string;
        }
      ];
      buttonLinkTelefon: string;
      buttonLinkEmail: string;
      buttonIcon: string;
      inhaltstext: string;
      bild: [
        {
          url: string;
        }
      ];
    }
  ];
  siteId: number;
}

const AbschlussElemente: React.FC<AbschlussElementeProps> = ({
  content,
  siteId,
}) => (
  <div style={{ marginBottom: 20 }}>
    {content.map((item, index) =>
      item.zitat ? (
        <Quote author={item.autor} text={item.zitat} key={index} />
      ) : item.buttonText ? (
        <Wrapper key={index}>
          <Button
            color={getColor(siteId)}
            icon={item.buttonIcon}
            text={item.buttonText}
            download={item.buttonLinkDatei.length > 0 ? true : false}
            link={
              item.buttonLinkDatei.length > 0
                ? item.buttonLinkDatei[0].url
                : item.buttonLinkUrl
                ? item.buttonLinkUrl
                : item.buttonLinkTelefon
                ? `tel:${item.buttonLinkTelefon}`
                : item.buttonLinkEmail
                ? `mailto:${item.buttonLinkEmail}`
                : ""
            }
          />
        </Wrapper>
      ) : item.inhaltstext ? (
        <Wrapper key={index}>
          <Fliesstext text={item.inhaltstext} centered siteId={siteId} />
        </Wrapper>
      ) : item.bild && item.bild.length > 0 ? (
        <Wrapper key={index}>
          <CenteredImage image={item.bild[0].url} />
        </Wrapper>
      ) : item.titel ? (
        <CenteredTextBGColor
          text={item.text}
          title={item.titel}
          siteId={siteId}
        ></CenteredTextBGColor>
      ) : (
        ""
      )
    )}
  </div>
);

export default AbschlussElemente;
