import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import { getColor } from "./helpers/getColor";
import Wrapper from "./Wrappers/Wrapper";

const CenteredTextBGColorElem = styled.article`
  padding: 20px;
  background: ${(props) => props.bgcolor};
  text-align: center;

  h4,
  p,
  b {
    color: white;
  }

  h4 {
    font: 20px / 30px "FS Medium";
    margin-bottom: 10px;
  }

  p {
    display: block;
    font: 16px / 21px "FS Regular";
    text-decoration: none;
    margin-bottom: 12px;

    b,
    strong {
      color: white;
      font-family: "FS Medium";
    }
  }

  .line {
    width: 100%;
    margin: 4px auto 16px;
    height: 1px;
    background: white;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 30px;

    h4,
    p,
    b {
      width: 80%;
      margin: 0 auto;
    }

    p {
      margin-bottom: 16px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 40px;

    .line {
      margin-bottom: 37px;
    }

    p {
      font: 21px / 30px "FS Regular";
      margin-bottom: 37px;
    }

    h4 {
      font: 28px / 36px "FS Medium";
      margin-bottom: 16px;
    }
  }
`;

const CenteredTextBGColor = ({ siteId, text, title }) => (
  <CenteredTextBGColorElem bgcolor={getColor(siteId)}>
    <Wrapper wider>
      <h4>{title}</h4>
      <div className="line" />
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </Wrapper>
  </CenteredTextBGColorElem>
);

export default CenteredTextBGColor;
