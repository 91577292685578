import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Wrapper from "../Wrappers/Wrapper";

const QuoteElem = styled.section`
  padding: 46px 0;
  text-align: center;

  .icon {
    display: block;
    width: 29.4px;
    height: 23.69px;
    margin: 0 auto 10px;
  }

  blockquote,
  blockquote p {
    font: 24px / 35px "FS Light";
    margin-bottom: 24px;

    span,
    b,
    strong {
      font: 29px / 35px "Mrs Eaves Bold Italic";
    }
  }

  cite {
    font: 11px / 13px "FS Regular";
    text-transform: uppercase;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 90px 0 100px;

    .icon {
      width: 49.64px;
      height: 40px;
      margin: 0 auto 15px;
    }

    blockquote,
    blockquote p {
      font: 42px / 50px "FS Light";
      margin-bottom: 30px;

      span,
      b,
      strong {
        font: 51px / 61px "Mrs Eaves Bold Italic";
      }
    }

    cite {
      font: 18px / 22px "FS Regular";
    }
  }
`;

interface QuoteProps {
  text: string;
  autor: string;
}

const Quote = ({ text, author }) => (
  <Wrapper>
    <QuoteElem>
      <img
        className="icon"
        src="/assets/gestaltungselemente/icon-quote.svg"
        alt="quote icon"
      />
      <blockquote dangerouslySetInnerHTML={{ __html: text }} />
      <cite>{author}</cite>
    </QuoteElem>
  </Wrapper>
);

export default Quote;
