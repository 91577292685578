import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import MailButton from "./MailButton";

const ItemElem = styled.article`
  text-align: center;

  h4 {
    font: 20px / 30px "FS Medium";
    margin-bottom: 20px;
  }

  h5 {
    font: 18px / 22px "FS Medium";
    margin-bottom: 10px;
  }

  a,
  p {
    display: block;
    font: 18px / 22px "FS Regular";
    text-decoration: none;
    margin-bottom: 18px;
  }

  display: none;

  @media screen AND (min-width: 1024px) {
    width: calc((100% - 120px) / 3);
    display: flex;
    flex-direction: column;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
    width: calc((100% - 180px) / 3);

    h4 {
      font: 28px / 36px "FS Medium";
      margin-bottom: 40px;
    }

    h5 {
      font: 24px / 36px "FS Medium";
      margin-bottom: 12px;
    }

    a,
    p {
      font: 24px / 36px "FS Regular";
      margin-bottom: 22px;
    }
  }
`;

const MitarbeiterTextItem = ({ color, content }) => (
  <ItemElem>
    {content.mitarbeitername ? <h5>{content.mitarbeitername}</h5> : ""}
    {content.text ? (
      <div dangerouslySetInnerHTML={{ __html: content.text }}></div>
    ) : (
      ""
    )}
    
    {content.darstellungsortTelefonnummer === false && content.telefon && (
      <a href={"tel:" + content.telefon} title="Telefon Link">
        {content.telefon}
      </a>)}
    {content.email ? <MailButton color={color} email={content.email} /> : ""}
  </ItemElem>
);

export default MitarbeiterTextItem;
