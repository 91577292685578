import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const ButtonElem = styled.a`
  text-decoration: none;
  background: ${(props) => props.buttonColor};
  margin: 30px auto 36px;
  text-align: center;
  padding: 10px 15px 12px;
  grid-template-columns: auto 35px;
  max-width: 100%;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;

  * {
    color: white;
  }

  > div {
    font: 24px / 30px "FS Light";
    width: 100%;

    span,
    b,
    strong {
      font: 28px / 30px "Mrs Eaves Bold Italic";
    }

    img {
      width: 25px;
      height: 20px;
      margin: 0 auto;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    border-radius: 12px;
    padding: 5px 40px 7px;
    display: grid;
    width: max-content;
    grid-template-columns: auto 35px;
    gap: 0;
    grid-column-gap: 26px;
    text-align: left;
    margin: 40px auto 90px;

    > div {
      font: 42px / 61px "FS Light";

      &:last-of-type {
        margin-top: 6px;
      }

      img {
        width: 39.96px;
        height: 31.96px;
        margin: 0;
      }

      span,
      b,
      strong {
        font: 50px / 61px "Mrs Eaves Bold Italic";
      }
    }
  }
`;

interface ButtonProps {
  text: string;
  link: string;
  color: string;
  icon: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  link,
  color,
  icon,
  download,
}) => (
  <ButtonElem
    href={link}
    buttonColor={color}
    target={download ? "_blank" : "_self"}
  >
    <div dangerouslySetInnerHTML={{ __html: text }}></div>
    {icon && icon != "keinIcon" ? (
      <div>
        <img src={"/assets/gestaltungselemente/" + icon} />
      </div>
    ) : (
      ""
    )}
  </ButtonElem>
);

export default Button;
