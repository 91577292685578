export const getImgix = (src, imgixParams) => {
  const newSrc = `${src}?auto=format,compress${
    imgixParams.q ? "&q=" + imgixParams.q : "&q=20"
  }${imgixParams.fit ? "&fit=" + imgixParams.fit : ""}${
    imgixParams.crop ? "&crop=" + imgixParams.crop : ""
  }${imgixParams.w ? "&w=" + imgixParams.w : ""}${
    imgixParams.h ? "&h=" + imgixParams.h : ""
  }`;

  const srcSet = src + "&dpr=1 1x, " + src + "&dpr=2 2x";

  return { newSrc, srcSet };
};
