import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const WrapperElem = styled.div`
  width: 100%;
  > * {
    display: none;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > * {
      display: inherit;
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1920px;
    padding: 30px 42px;
    margin: 0 auto;

    &.right {
      justify-content: flex-end;
    }
  }
`;

const StoererWrapper = ({ children, alignment }) => (
  <WrapperElem className={alignment == "obenRechts" ? "right" : ""}>
    {children}
  </WrapperElem>
);

export default StoererWrapper;
