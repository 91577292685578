import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../styles/variables";
import { getImgix } from "./helpers/getImgix";

const ImageElem = styled.div`
  width: 100%;
  margin: 30px auto 36px;
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;

  > div {
    background-size: cover;
    margin: 0 auto;
    height: auto;
    max-width: 300px;
    max-height: 300px;

    > img {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin: 90px 0 100px;

    > div {
      width: 100%;
      width: 420px;
      height: 420px;
    }
  }
`;

interface CenteredImage {
  image: string;
}

const CenteredImage = ({ image }) => (
  <ImageElem src={getImgix(image, imgix.halfImage).newSrc}>
    <div>
      <img src="/assets/gestaltungselemente/spirale.svg" />
    </div>
  </ImageElem>
);

export default CenteredImage;
