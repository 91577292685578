import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../styles/variables";
import BackgroundWrapper from "./BackgroundWrapper";
import Stoerer from "./Stoerer";
import StoererWrapper from "./Wrappers/StoererWrapper";
import Wrapper from "./Wrappers/Wrapper";

import ImgixImg from "./helpers/ImgixImg";

const LandingElem = styled.div`
  height: 41.25vw;
  width: 100vw;
  position: relative;
  text-align: center;
  box-shadow: inset 0 -120px 120px -120px black;
  box-sizing: content-box;
  overflow: hidden;

  .background {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 41.25vw;
    min-width: 100vw;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;

    > img {
      display: block;
      width: 100%;
      height: 41.25vw;
      object-fit: cover;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 41.25vw;
    padding: 18px 15px 8px;
  }

  &.unten {
    .content {
      justify-content: flex-end;
    }
  }

  h1,
  h2 {
    font: 32px / 47px "FS Light";
    color: white;
    max-width: 980px;
    margin: 0 auto;
  }

  span,
  b,
  strong {
    font: 37px / 47px "Mrs Eaves Bold Italic";
    color: white;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    width: 100vw;
    max-width: 1920px;
    max-height: 792px;

    .background {
      display: flex;
      flex-direction: row;
      justify-content: center;
      max-height: 792px;
      min-width: 100vw;
      max-width: 1920px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      z-index: -1;

      > img {
        display: block;
        object-fit: cover;
        margin: auto 0;
      }
    }

    h1,
    h2 {
      font: 48px / 58px "FS Light";
    }

    span,
    b,
    strong {
      font: 57px / 58px "Mrs Eaves Bold Italic";
    }

    /* &.startseite {
      height: unset;
      min-height: 41.25vw;
      max-height: unset;
    } */
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin: 0 auto;
    height: 100%;

    .content {
      padding: 18px 0;
      box-shadow: inset 0 -160px 160px -160px black;
      min-height: 41.25vw;
      height: unset;
      max-height: 792px;
    }

    &.startseite {
      min-height: 41.25vw;
      max-height: unset;

      .background {
        height: 100%;
        min-height: 41.25vw;

        > img {
          height: 100%;
          min-height: 41.25vw;
        }
      }
    }

    h1,
    h2 {
      font: 80px / 96px "FS Light";
    }

    b,
    span,
    strong {
      font: 96px / 96px "Mrs Eaves Bold Italic";
    }
  }

  @media screen AND (min-width: 1920px) {
    min-height: unset;
    height: 792px;

    .content {
      height: 41.25vw;
      max-height: 792px;
      min-height: unset;
      .background {
        min-height: unset;
        height: 792px;
        min-width: unset;
        width: 1920px;

        > img {
          min-height: unset;
          width: 1920px;
          height: 792px;
        }
      }
    }
    &.startseite {
      min-height: unset;
      height: 792px;
      .content {
        min-height: unset;
        height: 792px;
        .background {
          min-height: unset;
          height: 792px;
          min-width: unset;
          width: 1920px;

          > img {
            min-height: unset;
            width: 1920px;
            height: 792px;
          }
        }
      }
    }
  }
`;

interface LandingProps {
  title: string;
  image: string;
}

const Landing: React.FC<LandingProps> = ({
  title,
  image,
  link,
  stoerer,
  alignment,
  startseite,
}) => {
  const [showStoerer, setShowStoerer] = useState(false);

  useLayoutEffect(() => {
    setShowStoerer(true);
  }, []);

  return (
    <BackgroundWrapper>
      <LandingElem
        // src={image}
        // imgixParams={imgix.fullImage}
        className={
          (alignment && startseite == "untenRechts") ||
          (alignment && startseite == "untenLinks")
            ? "unten startseite"
            : alignment == "untenRechts" || alignment == "untenLinks"
            ? "unten"
            : startseite
            ? "startseite"
            : ""
        }
      >
        <div className="content">
          <div className="background">
            <ImgixImg src={image} imgixParams={imgix.fullImage} />
          </div>
          {showStoerer && typeof window != "undefined" ? (
            <>
              {navigator.userAgent.indexOf("Safari") != -1 &&
              navigator.userAgent.indexOf("Chrome") == -1 &&
              stoerer ? (
                <>
                  <div>&nbsp;</div>
                  <Wrapper>
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>
                  </Wrapper>
                </>
              ) : (
                <>
                  <StoererWrapper alignment={alignment}>
                    <Stoerer
                      content={stoerer}
                      alignment={alignment}
                      link={link}
                    />
                  </StoererWrapper>
                  <Wrapper>
                    <div dangerouslySetInnerHTML={{ __html: title }}></div>
                  </Wrapper>
                </>
              )}
            </>
          ) : (
            <>
              <div>&nbsp;</div>
              <Wrapper>
                <div dangerouslySetInnerHTML={{ __html: title }}></div>
              </Wrapper>
            </>
          )}
        </div>
      </LandingElem>
    </BackgroundWrapper>
  );
};

export default Landing;
