import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const StoererElem = styled.div`
  display: none;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: inline-block;
    overflow: visible;

    > a {
      width: max-content;
      display: block;
      padding: 50%;
      background: url("/assets/gestaltungselemente/stoerer.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: default;
      text-decoration: none;
      pointer-events: none;

      h2 {
        color: #000;
        font: 29px / 35px "FS Medium";
        margin-bottom: 18px;
      }
      h3 {
        color: #000;
        font: 19px /23px "FS Medium";
      }
    }

    &.link {
      > a {
        cursor: pointer;
        pointer-events: auto;
      }
    }
    &.right {
      > a {
        margin-left: -100%;
      }
    }
  }
`;

const Stoerer = ({ content, alignment, link }) => {
  const stoererLink = () => {
    if (link && link.length > 0) {
      if (link[0].linkUrl) {
        return link[0].linkUrl;
      } else if (link[0].datei && link[0].datei.length > 0) {
        return link[0].datei[0].url;
      } else if (link[0].telefon) {
        return "tel:" + link[0].telefon;
      } else if (link[0].telefon) {
        return "mailto:" + link[0].email;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <div>
      <StoererElem
        className={
          alignment == "obenRechts" && stoererLink()
            ? "link right"
            : stoererLink()
            ? "link"
            : ""
        }
      >
        <a
          dangerouslySetInnerHTML={{ __html: content }}
          className={stoererLink() ? "link" : ""}
          href={stoererLink()}
          target={stoererLink() ? "_blank" : "_self"}
        ></a>
      </StoererElem>
    </div>
  );
};

export default Stoerer;
