import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const ButtonDiv = styled.a`
  display: block;
  background: ${(props) => props.buttonColor};
  margin: 0 auto;
  padding: 6px 10px;
  border-radius: 8px;
  width: max-content;

  img {
    display: block;
    width: 24px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 76px;
    height: 52px;
    padding: 12px 22px;
    border-radius: 12px;

    img {
      width: 32.33px;
      height: 25.87px;
    }
  }
`;

const MailButton = ({ color, email }) => (
  <ButtonDiv href={"mailto:" + email} buttonColor={color} title="Email Kontakt">
    <img src="/assets/gestaltungselemente/icon-mail.svg" alt="Brief Icon" />
  </ButtonDiv>
);

export default MailButton;
