import { graphql } from "gatsby";
import React from "react";
import AbschlussElemente from "../components/AbschlussElemente";
import { getColor } from "../components/helpers/getColor";
import Mitarbeiter from "../components/Mitarbeiter/Mitarbeiter";
import DefaultLayout from "../layouts/default";
import { chunk } from "lodash";
import Landing from "../components/Landing";
import PostLandingText from "../components/Text/PostLandingText";
import Kontakt from "../components/Kontakt";

interface UeberUnsProps {
  data: {
    cms: {
      main: {
        title: string;
        id: number;
        siteId: number;
        metaBeschreibung: string;
        mitarbeiter: [
          {
            job: string;
            bild: [
              {
                url: string;
              }
            ];
            mitarbeitername: string;
            telefon: string;
            email: string;
          }
        ];
        landingBild: [
          {
            url: string;
          }
        ];
        landingTitelStartseite: string;
        postLandingtext: string;
        abschlussElemente: [
          {
            bild: [
              {
                url: string;
              }
            ];
            zitat: string;
            autor: string;
            buttonText: string;
            buttonLinkUrl: string;
            buttonLinkDatei: [
              {
                url: string;
              }
            ];
            buttonLinkTelefon: string;
            buttonLinkEmail: string;
            buttonIcon: string;
            inhaltstext: string;
          }
        ];
      };
    };
  };
}

export const MitarbeiterQuery = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      main: entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId
        ... on CMS_main_ueberUns_Entry {
          metaBeschreibung
          landingTitelStartseite
          landingBild {
            url
          }
          postLandingText
          mitarbeiter {
            ... on CMS_mitarbeiter_mitarbeiter_BlockType {
              job
              bild {
                url
              }
              mitarbeitername
              text
              telefon
              darstellungsortTelefonnummer
              email
              gruppenzuordnung
            }
          }

          abschlussElemente {
            ... on CMS_abschlussElemente_bild_BlockType {
              bild {
                url
              }
            }
            ... on CMS_abschlussElemente_zitat_BlockType {
              zitat
              autor
            }
            ... on CMS_abschlussElemente_button_BlockType {
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstext_BlockType {
              inhaltstext
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstextBgColor_BlockType {
              titel
              text
            }
          }
        }
      }

      kontakt: entry(section: "data", type: "data", siteId: [$siteId]) {
        title
        ... on CMS_data_data_Entry {
          adresseLine1
          plzUndOrt
          telefon
          email
        }
      }
    }
  }
`;

const UeberUns: React.FC<UeberUnsProps> = ({ data }) => {
  const color = getColor(data.cms.main.siteId);

  const mitarbeiter = (mitarbeiterItems) => {
    const completeSorted = [];
    for (let i = 1; i <= 4; i++) {
      let currentLevelItems = mitarbeiterItems.filter(
        (item) => item.gruppenzuordnung === i
      );
      if (currentLevelItems.length > 0) {
        let chunkedItems = chunk(currentLevelItems, 3);
        completeSorted.push(chunkedItems);
      }
    }
    const returnArray = completeSorted.map((item) =>
      item.map((arr, index) => {
        return (
          <Mitarbeiter color={color} content={arr} key={index}></Mitarbeiter>
        );
      })
    );

    return returnArray;
  };

  return (
    <DefaultLayout
      siteId={data.cms.main.siteId}
      description={data.cms.main.metaBeschreibung}
      title={data.cms.main.title}
    >
      {data.cms.main.landingBild && data.cms.main.landingBild.length > 0 ? (
        <Landing
          title={data.cms.main.landingTitelStartseite}
          image={data.cms.main.landingBild[0].url}
        />
      ) : (
        ""
      )}
      {data.cms.main.postLandingText ? (
        <PostLandingText text={data.cms.main.postLandingText} />
      ) : (
        ""
      )}

      {data.cms.main.mitarbeiter && data.cms.main.mitarbeiter.length > 0
        ? mitarbeiter(data.cms.main.mitarbeiter)
        : ""}

      {data.cms.main.abschlussElemente.length > 0 ? (
        <AbschlussElemente
          content={data.cms.main.abschlussElemente}
          siteId={data.cms.main.siteId}
        />
      ) : (
        ""
      )}
      <Kontakt
        siteId={data.cms.main.siteId}
        content={data.cms.kontakt}
      ></Kontakt>
    </DefaultLayout>
  );
};

export default UeberUns;
