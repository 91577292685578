import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Wrapper from "../Wrappers/Wrapper";

const TextDiv = styled.div`
  text-align: left;
  margin: 30px 0 36px;

  p {
    font: 21px / 32px "Mrs Eaves Regular";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin: 40px 0 90px;

    p {
      font: 32px / 49px "Mrs Eaves Regular";
    }
  }
`;

interface PostLandingTextProps {
  text: string;
}

const PostLandingText: React.FC<PostLandingTextProps> = ({ text }) => (
  <Wrapper>
    <TextDiv dangerouslySetInnerHTML={{ __html: text }}></TextDiv>
  </Wrapper>
);

export default PostLandingText;
