import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Wrapper from "../Wrappers/Wrapper";
import MitarbeiterItem from "./MitarbeiterItem";
import MitarbeiterTextItem from "./MitarbeiterTextItem";

const GridDiv = styled.div`
  margin-top: 40px;

  @media screen AND (min-width: 1024px) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;

    > article {
      margin-right: 40px;
      width: calc((100% - 120px) / 3);
    }

    > article:last-of-type {
      margin-right: 0;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > article {
      width: calc((100% - 180px) / 3);
      margin-right: 60px;
    }
  }
`;

const Mitarbeiter = ({ color, content }) => (
  <Wrapper>
    <GridDiv>
      {content.map((item, index) => (
        <MitarbeiterItem color={color} content={item} key={index} />
      ))}
    </GridDiv>
    <GridDiv>
      {content.map((item, index) => (
        <MitarbeiterTextItem color={color} content={item} key={index} />
      ))}
    </GridDiv>
  </Wrapper>
);

export default Mitarbeiter;
